import { Injectable, inject } from '@angular/core';
import { Route } from '@angular/router';
import { Auth } from '@core/service/auth';
import { Menu, MenuItem } from './menu.interface';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private _auth = inject(Auth);

    buildMenu(obj: Route[], pathPrefix: string = ''): Menu {
        const tmpMenu: Menu = [];
        obj.forEach((item: Route) => {
            if (
                item.data &&
                item.data['isNav'] &&
                item.data['navTitle'] &&
                item.data['permissionId'] &&
                this._auth.checkPermission(item.data['permissionId'])
            ) {
                const tmp: MenuItem = {
                    path: [pathPrefix, item.path || ''],
                    icon: item.data['icon'],
                    name: item.data['navTitle'],
                };

                if (item.data['icon']) {
                    tmp.icon = item.data['icon'];
                }

                if (item.data['badgeSelector']) {
                    tmp.badgeSelector = item.data['badgeSelector'];
                }

                tmpMenu.push(tmp);
            }
        });
        return tmpMenu;
    }
}
