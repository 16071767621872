<div id="wrap">
    <header>
        <app-header [subMenu]="subMenu" />
    </header>
    <aside>
        <div id="logo">
            <svg-icon src="/assets/images/logo-m.svg" />
        </div>
        <div id="menu">
            <app-sidenav [routes]="routes"
                         [badges$]="badges$" />
        </div>
    </aside>
    <main>
        <router-outlet />
        <footer>
            <app-footer />
        </footer>
    </main>
</div>
