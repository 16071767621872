import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    inject,
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    Route,
    Router,
    RouterModule,
} from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Auth } from '@core/service/auth';
import { MenuService } from '@shared/layouts/menu/menu.service';
import { Menu, SubMenu } from '@shared/layouts/menu/menu.interface';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [AsyncPipe, RouterModule, AngularSvgIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    private _auth = inject(Auth);
    private _router = inject(Router);
    private _activatedRoute = inject(ActivatedRoute);
    private _cdr = inject(ChangeDetectorRef);
    private _menuService = inject(MenuService);

    public user$ = this._auth.user$;
    public menu: Menu = [];

    private _subMenu: Map<string, Route[]> = new Map();
    @Input() set subMenu(val: SubMenu | undefined | null) {
        if (val) {
            for (const menu of val) {
                if (menu.routes.length > 0) {
                    this._subMenu.set(menu.path, menu.routes);
                }
            }

            this.buildSubmenu();
        }
    }

    private subs$: Subscription = this._router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
            this.buildSubmenu();
        });

    private buildSubmenu() {
        const path =
            this._activatedRoute.snapshot.firstChild?.routeConfig?.path;

        if (path) {
            if (this._subMenu.has(path)) {
                this.menu = this._menuService.buildMenu(
                    this._subMenu.get(path)!,
                    path,
                );
            } else {
                this.menu = [];
            }
            this._cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        if (this.subs$) {
            this.subs$.unsubscribe();
        }
    }
}
