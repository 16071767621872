import { Injectable, inject } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Config } from '@core/service/config';

@Injectable({
    providedIn: 'root',
})
export class TemplatePageTitleStrategy extends TitleStrategy {
    private readonly _title = inject(Title);
    private _config = inject(Config);

    private titlePostfix = this._config.get('titlePostfix');

    constructor() {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState);
        if (title !== undefined) {
            this._title.setTitle(`${title}${this.titlePostfix}`);
        }
    }
}
