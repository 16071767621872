import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from './auth';
import { TelemetryServiceInjection } from '@shared/service/methods/auth/telemetry';

@Injectable({
    providedIn: 'root',
})
class Guard {
    private _auth = inject(Auth);

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean {
        return this._auth.check(route.data['permissionId']);
    }

    canActivateChild(route: ActivatedRouteSnapshot): boolean {
        return this._auth.checkPermission(route.data['permissionId']);
    }
}

export const canActivateFn: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    return inject(Guard).canActivate(route);
};

export const canActivateChildFn: CanActivateChildFn = (
    route: ActivatedRouteSnapshot
) => {
    const guard = inject(Guard);
    const hasAccess = guard.canActivateChild(route);

    if (hasAccess) {
        const _apiAuthTelemetryService = inject(TelemetryServiceInjection);
        _apiAuthTelemetryService
            .sendTelemetry({
                permission: route.data['permissionId'],
            })
            .subscribe();
    }

    return hasAccess;
};
