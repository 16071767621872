import { ApplicationRef, Injectable, OnDestroy, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, concat, first, interval } from 'rxjs';
import { Toast } from '@core/service/toast';

@Injectable({
    providedIn: 'root',
})
export class UpdateService implements OnDestroy {
    private _updates = inject(SwUpdate);
    private _toast = inject(Toast);
    public _applicationRef = inject(ApplicationRef);

    private subs$: Subscription[] = [];

    constructor() {
        if (this._updates.isEnabled) {
            console.log('SwUpdate Start');
            const sub$ = this._updates.versionUpdates.subscribe((evt) => {
                console.log('SwUpdate EVENT');
                switch (evt.type) {
                    case 'VERSION_DETECTED':
                        console.log(
                            `Downloading new app version: ${evt.version.hash}`
                        );
                        break;
                    case 'VERSION_READY':
                        console.log(
                            `Current app version: ${evt.currentVersion.hash}`
                        );
                        console.log(
                            `New app version ready for use: ${evt.latestVersion.hash}`
                        );
                        this._toast.info(
                            'Вышла новая версия, обновите страницу'
                        );

                        break;
                    case 'VERSION_INSTALLATION_FAILED':
                        console.log(
                            `Failed to install app version '${evt.version.hash}': ${evt.error}`
                        );
                        break;
                }
            });
            this.subs$.push(sub$);

            this.checkUpdate();
        }
    }

    checkUpdate() {
        // Allow the app to stabilize first, before starting
        // polling for updates with `interval()`.
        const appIsStable$ = this._applicationRef.isStable.pipe(
            first((isStable) => isStable === true)
        );
        const everySixHours$ = interval(5 * 60 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(
            appIsStable$,
            everySixHours$
        );

        const sub$ = everySixHoursOnceAppIsStable$.subscribe(async () => {
            try {
                const updateFound = await this._updates.checkForUpdate();
                console.log(
                    updateFound
                        ? 'A new version is available.'
                        : 'Already on the latest version.'
                );
                console.log(
                    '-----------------------------------------------------------------------------'
                );
            } catch (err) {
                console.error('Failed to check for updates:', err);
                console.log(
                    '-----------------------------------------------------------------------------'
                );
            }
        });
        this.subs$.push(sub$);
    }

    ngOnDestroy(): void {
        for (const sub of this.subs$) {
            sub.unsubscribe();
        }
    }
}
