import { Component, Input } from '@angular/core';
import { Route, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HeaderComponent } from '@shared/layouts/header/header.component';
import { FooterComponent } from '@shared/layouts/footer/footer.component';
import { SidenavComponent } from '@shared/layouts/sidenav/sidenav.component';
import { Badge } from '@shared/layouts/sidenav/sidenav.interface';
import { SubMenu } from '../menu/menu.interface';

@Component({
    selector: 'app-page-layout',
    standalone: true,
    imports: [
        RouterOutlet,
        AngularSvgIconModule,
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
    ],
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
    // Параметры хедера
    @Input() subMenu?: SubMenu;

    // Параметры навигации
    @Input() routes!: Route[];
    @Input() badges$?: Observable<Badge>;
}
