import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UpdateService } from '@core/service/update/update.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
    private _updateService = inject(UpdateService);

    ngOnInit() {
        registerLocaleData(localeRu);
    }

    ngOnDestroy(): void {
        this._updateService.ngOnDestroy();
    }
}
