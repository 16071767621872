<nav class="nav">
    @for (item of menu; track item) {
        <a class="nav-item" [routerLink]="item.path" routerLinkActive="active">
            <div class="nav-inner">
                @if (item.badgeSelector && (badges$ | async); as badges) {
                    @if (badges[item.badgeSelector!] > 0) {
                        <div class="nav-badge center">
                            <b>{{ badges[item.badgeSelector!] }}</b>
                        </div>
                    }
                }
                @if (item.icon) {
                    <svg-icon
                        class="nav-item-icon"
                        src="/assets/images/menu-icons/{{ item.icon }}.svg"
                    />
                }
                <b class="nav-item-name big">{{ item.name }}</b>
            </div>
        </a>
    }
</nav>
