<div class="header" [class.has-menu]="menu.length > 1">
    @if (user$ | async; as user) {
        @if (user.logo_url) {
            <div class="image">
                <img src="{{ user.logo_url }}" />
            </div>
        } @else {
            <div class="name big">{{ user.name }}</div>
        }
    }

    @if (menu.length > 1) {
        <nav class="nav">
            @for (item of menu; track item) {
                <a
                    class="nav-item"
                    [routerLink]="item.path"
                    routerLinkActive="active"
                >
                    <b class="big">{{ item.name }}</b>
                </a>
            }
        </nav>
    }
</div>
