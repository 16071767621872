import { Component, Input, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MenuService } from '@shared/layouts/menu/menu.service';
import { Menu } from '@shared/layouts/menu/menu.interface';
import { Badge } from './sidenav.interface';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    standalone: true,
    imports: [AsyncPipe, RouterModule, AngularSvgIconModule],
})
export class SidenavComponent {
    private _menuService = inject(MenuService);

    @Input() set routes(val: Route[]) {
        this.menu = this._menuService.buildMenu(val);
    }
    @Input() badges$?: Observable<Badge>;

    public menu!: Menu;
}
